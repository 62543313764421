html {
  background: #fafdff;
}

body,
html {
  height: 100%;
  margin: 0;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  position: relative;
  height: 100%;
}
